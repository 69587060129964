<template>
  <main>
      <section class="profile">
        <div class="container">
          <div class="tabs">
            <div class="tabs__title">
              <div :class="`tabs__title-item ${activeTab === 0 ? 'active' : ''}`" @click="switchTab(0)">Личные данные</div>
              <div :class="`tabs__title-item ${activeTab === 1 ? 'active' : ''}`" @click="switchTab(1)">Квесты</div>
              <div :class="`tabs__title-item ${activeTab === 2 ? 'active' : ''}`" @click="switchTab(2)">История</div>
              <div :class="`tabs__title-item ${activeTab === 3 ? 'active' : ''}`" @click="switchTab(3)">Кошелек</div>
            </div>
          </div>
          <div class="tabs__contents">
            <div :class="`tabs__contents-item user-data ${activeTab === 0 ? 'active' : ''}`">
              <div class="user-data__img" v-bind="{ style: editMode === true ? 'cursor: pointer;' : '' }">
                <img :src="uploadedAvatar !== null ? uploadedAvatar : user.avatar_url" :alt="user.full_name"/>
                <input v-if="editMode" type="file" accept="image/*" @change="fileChange($event)" style="opacity: 0.0; position: sticky; top:0; left: 0; bottom: 0; right:0; width: 100%; height:100%; cursor: pointer;" />
              </div>
              <div class="user-data__content">
                <div class="user-data__content-item name" v-if="!editMode">
                  Имя Фамилия: <span>{{ user.full_name }}</span>
                </div>
                <div class="user-data__content-item name" v-else>
                  <div class="reg_input" style="width: 100% !important;">
                      <input type="text" v-model="fullName" style="height: 20px !important;" placeholder="Имя">
                  </div>
                </div>
                <div class="user-data__content-item phone" v-if="!editMode">
                  Телефон: <span>{{ user.phone_number }}</span>
                </div>
                <div class="user-data__content-item phone" v-else>
                  <div class="reg_input" style="width: 100% !important;">
                      <input type="text" v-model="phoneNumber" style="height: 20px !important;" placeholder="Телефон">
                  </div>
                </div>
                <div class="user-data__content-item email" v-if="!editMode">
                  E-mail: <span>{{ user.email }}</span>
                </div>
                <div class="user-data__content-item email" v-else>
                  <div class="reg_input" style="width: 100% !important;">
                      <input type="text" v-model="email" style="height: 20px !important;" placeholder="Почта">
                  </div>
                </div>
                <div class="btn-blue-shadow" v-if="!editMode" @click="editMode = true;">Редактировать информацию</div>
                <div class="btn-group" v-else style="margin-top: 30px; display: flex; justify-content: space-between">
                  <div class="btn-blue-shadow" @click="editMode = false; saveChanges();" style="margin-top: 0 !important;">Сохранить</div>
                  <div class="btn-blue-shadow" @click="editMode = false; cancelChanges();" style="margin-top: 0 !important; margin-left: 10px;">Отмена</div>
                </div>
              </div>
            </div>
            <div v-if="activeTab === 0" class="btn-blue-shadow" style="margin-top: 150px; max-width: 377px;margin-right: auto;margin-left: auto;margin-bottom: 200px;"><a href="/map">Начать</a></div>
            <div :class="`tabs__contents-item other-tab quest ${activeTab === 1 ? 'active' : ''}`">
              <div>
                <div class="quest-header">
                  <div>
                    <div class="selects">
                                  <div class="select custom-select">
                                    <select name="country" v-model="country_id">
                                      <option value="-1">Все</option>
                                      <option :value="item.id" :key="item.id" v-for="item in countries">{{ item.name }}</option>
                                    </select>
                                  </div>
                                  <div class="select custom-select">
                                    <select name="city" v-model="city_id">
                                      <option value="-1">Все</option>
                                      <option :value="item.id" :key="item.id" v-for="item in cities">{{ item.name }}</option>
                                    </select>
                                  </div>
                    </div>
                    <div class="how-use-balls" data-modal-btn="how-use-balls">Как пользоваться баллами?</div>
                  </div>
                  <div v-if="quests.length > 0">
                    <div class="h3">История прохождения квестов</div>
                    <div class="pages">
                      <a v-if="currentPage > 1" @click="this.currentPage--;">Предыдущая</a>
                      <a v-if="currentPage > 1" @click="this.currentPage--;">{{ this.currentPage - 1 }}</a>
                      <a class="active">{{ this.currentPage }}</a>
                      <a v-if="currentPage < pagesAmount" @click="this.currentPage++;">{{ this.currentPage + 1 }}</a>
                      <a v-if="currentPage < pagesAmount" @click="this.currentPage++;">Следующая</a>
                    </div>
                  </div>
                  <div v-else>
                    <span>Вы ещё не проходили ни одного квеста.</span>
                  </div>
                </div>
                <div class="quest-body" v-if="quests.length > 0">
                  <div class="quest__form">
                                <div class="quest__form-item" v-for="item in questsTable" :key="item.label">
                                  <div class="quest__form-title">{{  item.label }}</div>
                                  <div class="quest__form-inputs">
                                    <input v-for="quest in quests" disabled :key="quest.id" :value="item.value(quest)" type="text"/>
                                  </div>
                                </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="`tabs__contents-item other-tab quest ${activeTab === 2 ? 'active' : ''}`">
              <div>
              <div class="quest-header">
                <div>
                  <div class="selects">
                                  <div class="select custom-select">
                                    <select name="country" v-model="country_id">
                                      <option value="-1">Все</option>
                                      <option :value="item.id" :key="item.id" v-for="item in countries">{{ item.name }}</option>
                                    </select>
                                  </div>
                                  <div class="select custom-select">
                                    <select name="city" v-model="city_id">
                                      <option value="-1">Все</option>
                                      <option :value="item.id" :key="item.id" v-for="item in cities">{{ item.name }}</option>
                                    </select>
                                  </div>
                  </div>
                  <div class="how-use-balls" data-modal-btn="how-use-balls">Как пользоваться баллами?</div>
                </div>
                <div v-if="quests.length > 0">
                  <div class="h3">История списания/начисления баллов</div>
                    <div class="pages">
                      <a v-if="currentPage > 1" @click="this.currentPage--;">Предыдущая</a>
                      <a v-if="currentPage > 1" @click="this.currentPage--;">{{ this.currentPage - 1 }}</a>
                      <a class="active">{{ this.currentPage }}</a>
                      <a v-if="currentPage < pagesAmount" @click="this.currentPage++;">{{ this.currentPage + 1 }}</a>
                      <a v-if="currentPage < pagesAmount" @click="this.currentPage++;">Следующая</a>
                    </div>
                </div>
                <div v-else>
                  У вас ещё не было списаний/пополнений.
                </div>
              </div>
              <div class="quest-body" v-if="quests.length > 0">
                <div class="quest__form column-5">
                                <div class="quest__form-item" v-for="item in balanceTable" :key="item.label">
                                  <div class="quest__form-title">{{  item.label }}</div>
                                  <div class="quest__form-inputs">
                                    <input v-for="quest in quests" disabled :key="quest.id" :value="item.value(quest)" type="text"/>
                                  </div>
                              </div>
                </div>
              </div>
                </div>
            </div>
            <div :class="`tabs__contents-item wallet ${activeTab === 3 ? 'active' : ''}`">
<div class="wallet__content">
                <img src="/static/img/prev.png" alt="">
                <div>
                  <p>Вы можете пополнять свой кошелек денежными средствами.<br>Накопить нужную сумму и купить на эти деньги тур.</p>
                </div>
              </div>
              <div class="wallet_balance">
                <p>Баланс: <span>0р</span></p>
                <div class="btn-blue-shadow">Пополнить баланс</div>
              </div>            </div>
          </div>
        </div>
      </section>
    </main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileTab",
  data() {
    return {
      activeTab: 0,
      fullName: '',
      phoneNumber: '',
      email: '',
      avatar: null,
      editMode: false,
      uploadedAvatar: null,
      currentPage: 1,
      city_id: -1,
      country_id: -1,
      countries: [],
      questsTable: [
        {
          label: 'Название заведения',
          value: x => x.organisation ? x.organisation.name : "?"
        },
        {
          label: 'Страна и город',
          value: x => `${x.country ? x.country.name : "?"}, ${x.city ? x.city.name : "?"}`
        },
        {
          label: 'Пройденный квест',
          value: x => x.quest_name
        },
        {
          label: 'Количество баллов',
          value: x => x.points_amount
        },
      ],
      balanceTable: [
        {
          label: 'Название организации',
          value: x => x.organisation ? x.organisation.name : "Списано администратором"
        },
        {
          label: 'Дата',
          value: x => new Date(Date.parse(x.created_at)).toLocaleString()
        },
        {
          label: 'Страна и город',
          value: x => `${x.country ? x.country.name : "?"}, ${x.city ? x.city.name : "?"}`
        },
        {
          label: 'Количество баллов',
          value: x => x.points_amount
        },
        {
          label: 'Остаток',
          value: x => x.balance
        }
      ]
    }
  },
  computed: {
    ...mapGetters({user: 'activeUser', quests: 'questsHistory', pagesAmount: 'questsPages', cities: 'cities', _countries: 'countries'})
  },
  watch: {
    city_id() { this.currentPage = 1; this.updateQuestsLoading() },
    country_id() { this.currentPage = 1; this.updateCountry(); },
    currentPage() { this.updateQuestsLoading() }
  },
  methods: {
    ...mapActions({editProfile: 'editProfile', refreshProfile: 'refreshProfile', getBalanceHistory: 'getBalanceHistory', getQuests: 'getQuests', getCities: 'getCities', getCountries: 'getCountries' }),
    async switchTab(idx) {
      await this.$root.loading( async() => {
          this.activeTab = idx;
          if (this.activeTab === 1 || this.activeTab === 2) {
            this.currentPage = 1;
            await this.updateQuests();
            await this.getCountries();
            this.countries = this._countries;

          }
      })
            // eslint-disable-next-line
            enableSelects();
    },
    async updateCountry() {
      await this.$root.loading( async () => {
        await this.getCities(this.country_id);
        await this.updateQuests();
      })
      // eslint-disable-next-line
      enableSelects();
    },
    async updateQuestsLoading() {
      await this.$root.loading( async () => {
        await this.updateQuests();
      })
    },
    async updateQuests() {
      let data = { page: this.currentPage, city_id: this.city_id, country_id: this.country_id };
      if (this.city_id == -1) delete data.city_id;
      if (this.country_id == -1) delete data.country_id;

      if (this.activeTab === 1) await this.getQuests({ params: data });
      else await this.getBalanceHistory({ params: data });
    },
    async saveChanges() {
      await this.editProfile({ phone: this.phoneNumber, email: this.email, name: this.fullName, avatar: this.avatar });
      this.uploadedAvatar = null;
    },
    cancelChanges() {
      this.fullName = this.user.full_name;
      this.phoneNumber = this.user.phone_number;
      this.email = this.user.email;
      this.avatar = null;
      this.uploadedAvatar = null;
    },
    fileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.avatar = files[0];
      this.uploadedAvatar = URL.createObjectURL(this.avatar);
    }
  },
  async mounted() {
                // eslint-disable-next-line
            enableModals();
    this.$root.setTitle('Личный кабинет');
    await this.$root.loading(async () => {
        await this.refreshProfile();
        if (this.user) {
          this.fullName = this.user.full_name;
          this.phoneNumber = this.user.phone_number;
          this.email = this.user.email;
        } else window.location.refresh();
    });

  }
}
</script>

<style scoped>

</style>
