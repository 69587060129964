import axios from 'axios';

const state = {
    countries: [],
    cities: [],
    organisations: [],
    organisationTypes: [],
    mapData: {},
    partners: []
}

const getters = {
    countries: state => state.countries,
    cities: state => state.cities,
    organisations: state => state.organisations,
    organisationTypes: state => state.organisationTypes,
    currentMap: state => state.mapData,
    partners: state => state.partners
}

const actions = {
    async getCountries({ commit }) {
        let resp = await axios.get('/maps/filters');
        commit('setTypes', resp.data.org_types);
        commit('setCountries', resp.data.countries);
    },
    async getCities({ commit }, country) {
        let resp = await axios.get(`/maps/filters?country_id=${country}`);
        commit('setCities', resp.data.cities);
    },
    async getOrganisations({ commit }, city) {
        let resp = await axios.get(`/maps/filters?city_id=${city}`);
        commit('setOrganisations', resp.data.organisations);
        //commit('setTypes', resp.data.types);
    },
    async getMap({ commit }, city) {
        let resp = await axios.get(`/maps/get?city_id=${city}`);
        commit('setMap', resp.data.map);
        commit('setPartners', resp.data.partners);
    }
}


const mutations = {
    setTypes(state, types) {
        state.organisationTypes = types;
    },
    setCountries(state, countriesList) {
        state.countries = countriesList;
    },
    setCities(state, cities) {
        state.cities = cities;
        state.cities.push();
    },
    setOrganisations(state, organisations) {
        state.organisations = organisations;
    },
    setMap(state, mapData) {
        state.mapData = mapData;
    },
    setPartners(state, partners) {
        state.partners = partners;
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}