<template>
  <div>
    <main v-if="city === 0">
      <section class="hero-index">
        <div class="container">
          <div class="selects">
            <div class="select custom-select">
              <select ref="country" name="country" v-model="this.country" @change="updateData($refs.country.value)">
                <option v-for="country in this.countries" :key="country.id" :value="country.id">{{
                    country.name
                  }}
                </option>
              </select>
            </div>
            <div class="select custom-select ">
              <select name="city" ref="city" @change="citySet($refs.city.value)">
                <option value="-1">Выберите город...</option>
                <option v-for="city in this.cities" :key="city.id" :value="city.id">{{ city.name }}</option>
              </select>
            </div>
          </div>
          <div class="main-videos">
            <div class="main-videos__slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide"  v-for="video in videos" :key="video.id"><a class="main-videos__item" href="#" :data-video-url="video.video_url"><img
                    :src="video.thumbnail_url" alt=""/>
                  <div class="btn-play"></div>
                </a></div>
              </div>
              <div class="swiper-button-prev main-btn"></div>
              <div class="swiper-button-next main-btn"></div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <main v-else>
      <section class="hero-main">
        <div class="container">
          <div class="selects">
            <div class="select custom-select">
              <select ref="country" name="country" v-model="this.country" @change="updateData($refs.country.value)">
                <option v-for="country in this.countries" :key="country.id" :value="country.id">{{
                    country.name
                  }}
                </option>
              </select>
            </div>
            <div class="select custom-select">
              <select name="city" ref="city" @change="citySet($refs.city.value)">
                <option v-for="city in this.cities" :key="city.id" :value="city.id">{{ city.name }}</option>
              </select>
            </div>
            <div class="select custom-select">
              <select ref="organisationType" @change="setTypeFilter($refs.organisationType.value)">
                <option value="-1">Все</option>
                <option v-for="orgType in this.organisationTypes" :key="orgType.id" :value="orgType.id">{{
                    orgType.name
                  }}
                </option>
              </select>
            </div>
            <div class="select custom-select">
              <select name="organisation" ref="organisation" @change="setOrgFilter($refs.organisation.value)">
                <option value="-1">Все</option>
                <option v-for="organisation in this.organisations" :key="organisation.id" :value="organisation.id">
                  {{ organisation.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="map_scrollable">
          <img class="map_img" :src="map.image_url">
          <div class="mark" v-for="(mark, index) in filteredMarks" :key="index">
          <div class="geo" :style="{left: mark.rel_x+'px', top: mark.rel_y+'px'}" @click="this.modalId === mark.organisation.id ? this.modalId = 0 : this.modalId = mark.organisation.id;">
            <img src="/static/img/geo.png">
            <img class="icon" :style="{ marginLeft: mark.organisation.type.icon_url.includes('home.svg') && mark.organisation.quest_enabled ? '5px' : '0px' }" :src="mark.organisation.type.icon_url.includes('home.svg') && mark.organisation.quest_enabled ? mark.organisation.type.icon_url.replace('home.svg', 'star_home.svg') : mark.organisation.type.icon_url">
          </div>
          <div
              class="modal"
              :style="{left: getModalPosition(mark.rel_x, mark.rel_y, mark.organisation).x+'px', top: getModalPosition(mark.rel_x, mark.rel_y, mark.organisation).y+'px', display: this.modalId === mark.organisation.id ? '' : 'none'}"
          >
            <div class="m-title">
              <div class="left_img">
                <img :src="mark.organisation.avatar_link" :alt="mark.organisation.name">
              </div>
              <div class="right_title">
                <span>{{ mark.organisation.name }}</span>
                <div class="quest_icons-list">
                  <div class="quest_ico" v-if="mark.organisation.quest_enabled">
                    <img class="icon" src="/static/icons/star.svg">
                  </div>
                  <div class="quest_ico">
                    <img class="icon" src="/static/img/Group396.svg">
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-blue-shadow_reg" v-if="mark.organisation.referral_link !== null"><a style="text-decoration: none; color: #000;" target="_blank" :href="mark.organisation.referral_link">Ссылка на сайт</a></div>
            <div class="btn-blue-shadow_reg" v-if="mark.organisation.quest_enabled"><a :href="mark.organisation.quest_link" target="_blank"
                                                style="text-decoration: none; color: #000;">Пройти квест</a></div>
          </div>
            </div>
        </div>

      </section>
      <section class="partners">
        <div class="container">
          <h2 class="h2">Партнеры</h2>
          <div class="partners__list" style="display: flex; justify-content: space-between" >
            <div class="partners__item" v-for="partner in partners" :key="partner.id">
              <div class="partners__item-img"><img :src="partner.avatar_link" alt=""/></div>
              <div class="partners__item-body">
                <div class="partners__item-name">{{ partner.name }}</div>
                <div class="partners__item-contact" style="white-space: pre-wrap">{{ partner.contacts }}</div>
                <div class="partners__item-links" ><a :href="partner.company_link" >Ссылка на компанию</a><br/></div>
              </div>
              <a class="partners__item-btn" :href="partner.referral_link">Перейти</a>
            </div>
          </div>
        </div>
      </section>
    </main>

  </div>
</template>

<style scoped>
.hero-main {
      max-height: 700px;
    padding-top: 50px;
    min-height: 700px;
}
</style>

<script>
import {mapGetters, mapActions} from "vuex";


export default {
  name: 'MainPage',
  data() {
    return {
      city: 0,
      country: 0,
      countries: [],
      cities: [],
      type: -1,
      orgId: -1,
      modalId: 0
    }
  },
  computed: {
    ...mapGetters({
      _countries: 'countries',
      _cities: 'cities',
      organisations: 'organisations',
      organisationTypes: 'organisationTypes',
      map: 'currentMap',
      partners: 'partners',
      videos: 'videos'
    }),
    filteredMarks: function() {
      const orgId = this.orgId;
      const typeId = this.type;
      return this.map.marks.filter((mark) => {
        if (mark.organisation.is_hidden) return false;
        if (orgId !== -1 && mark.organisation.id !== orgId) return false;
        else return !(typeId !== -1 && mark.organisation.type.id !== typeId);
      });
    }
  },
  methods: {
    ...mapActions({
      getCountries: 'getCountries',
      getCities: 'getCities',
      getOrganisations: 'getOrganisations',
      getMap: 'getMap',
      fetchVideos: 'fetchVideos'
    }),
    async citySet(val) {
      await this.$root.loading(async () => {
        this.city = val;
        this.rearrange(this.cities, val);
        await this.getOrganisations(val);
        await this.getMap(val);
      });
      // eslint-disable-next-line
      this.$nextTick(enableSelects);
    },
    async updateData(country) {
      await this.$root.loading(async () => {
        this.city = 0;
        this.country = country;
        this.rearrange(this.countries, country);
        await this.getCities(country);
        this.cities = this._cities;
      })
      // eslint-disable-next-line
      this.$nextTick(() => { enableSelects(); enableSlider(); });
    },
    setTypeFilter(v) {
      this.type = parseInt(v);
    },
    rearrange(arr, id) {
      let index = 0;
      for (let i = 0; i < arr.length; ++i) {
        if (arr[i].id == id) index = i;
      }
      const element = arr.splice(index, 1)[0];
      arr.splice(0, 0, element);
    },
    setOrgFilter(organisationId) {
      this.orgId = parseInt(organisationId);
    },
    getModalPosition(x, y, org) {
      let newY = y - 239;
      if (!org.quest_enabled) newY += 50;
      let newX;
      if (1453 <= x) {
        newX = x - 372;
      } else {
        newX = x + 57;
      }
      return {x: newX, y: newY};
    }
  },

  async mounted() {
    this.$root.setTitle('Главная');
    await this.$root.loading(async () => {
      await this.fetchVideos();
      await this.getCountries();
      this.countries = this._countries;
      if (this.countries.length > 0)
        await this.updateData(this.countries[0].id);
    })
    // eslint-disable-next-line
    enableSelects();enableSlider();enableVideos();
  }
}
</script>

