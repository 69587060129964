import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex';

import users from './modules/user';
import map from './modules/map';
import videos from './modules/videos';

export const store = createStore({
  modules: {
    users,
    map,
    videos
  },
  plugins: [createPersistedState()]
})

