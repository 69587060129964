<template>
  <div>
 <main>
    <section class="hero-index">
      <div class="container">
        <p class="title" id="howItWorks">Как это работает?</p>
        <div class="content__left">

          <div class="main-quest__slider">
            <div class="swiper-wrapper">

              <div class="swiper-slide">
                <p class="desc" v-html="this.landingSettings.slider_1"></p>
                  <a href="/map" class="slide__button">Перейти в карту</a>
              </div>
              <div class="swiper-slide">
                <p class="desc" v-html="this.landingSettings.slider_2"></p>
                <a href="/map" class="slide__button">Перейти в карту</a>
              </div>
              <div class="swiper-slide">
                <p class="desc" v-html="this.landingSettings.slider_3"></p>
                <a href="/map" class="slide__button">Перейти в карту</a>
              </div>
            </div>


          </div>

          <div class="slider__box main-videos__slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide"><a class="main-videos__item" href="javascript:void(0)"><img src="/static/img/Slide2.png"
                    alt="" />
                </a></div>
              <div class="swiper-slide"><a class="main-videos__item" href="javascript:void(0)"><img src="/static/img/Slide1.png"
                    alt="" />
                </a></div>
              <div class="swiper-slide"><a class="main-videos__item" href="javascript:void(0)"><img src="/static/img/Slide3.png"
                    alt="" />
                </a></div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>

        <div class="content__right">

          <p class="desc" v-html="landingSettings.questbook_text"></p>

          <div class="img__box">
            <img src="/static/img/Frame2.png" alt="">
          </div>
        </div>


        <div class="content__relative">
          <p class="content__title" id="whyUs">Почему именно мы?</p>
          <div class="grid__box">
            <div class="grid_item">
              <img src="/static/img/ico1.svg" alt="" class="item_icon">
              <p v-html="landingSettings.reason_1"></p>
            </div>
            <div class="grid_item">
              <img src="/static/img/ico2.svg" alt="" class="item_icon">
              <p v-html="landingSettings.reason_2"></p>
            </div>
            <div class="grid_item">
              <img src="/static/img/ico3.svg" alt="" class="item_icon">
              <p v-html="landingSettings.reason_3"></p>
            </div>
            <div class="grid_item">
              <img src="/static/img/ico4.svg" alt="" class="item_icon">
              <p v-html="landingSettings.reason_4"></p>

            </div>
            <div class="grid_item">
              <img src="/static/img/ico5.svg" alt="" class="item_icon">
              <p v-html="landingSettings.reason_5"></p>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LandingPage",
  data() {
    return {
      landingSettings: {}
    }
  },
  async mounted() {
    // eslint-disable-next-line
    loadSlider();
    document.title = 'QuestBook';
    await this.$root.loading(async () => {
          let resp = await axios.get('/landing/settings?page=main');
          this.landingSettings = resp.data;
    });

  }
}

</script>
