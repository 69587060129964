<template>
    <main>
      <section class="hero-index">
        <div style="    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;" v-if="!dataSent">
          <h1 style="    margin: 25px 0;
">Заявка на участие</h1>

          <div>

            <div class="content_grid">
              <div class="content_left">
                <div class="reg_input">
                  <span>Выберите страну</span>
                  <select v-model="selectedCountry" @change="updateCities()">
                    <option value=""></option>
                    <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}</option>
                  </select>
                </div>
                <div class="reg_input">
                  <span>Тип организации</span>
                  <select v-model="orgType">
                    <option v-for="type in organisationTypes" :value="type.id" :key="type.id">{{ type.name }}</option>
                  </select>
                </div>
                <div class="reg_input">
                  <span>Юридические данные</span>
                  <input type="text" v-model="legalData" required placeholder="Введите данные">
                </div>
                <div class="reg_input">
                  <span>Ссылка на организацию</span>
                  <input type="text" v-model="orgLink" required placeholder="Укажите ссылку">
                </div>
                <div class="reg_input">
                  <span>Адрес организации</span>
                  <input type="text" v-model="address" required placeholder="Введите адрес">
                </div>

              </div>
              <div class="content_right">
                <div class="reg_input">
                  <span>Выберите город</span>
                  <select v-model="selectedCity">
                    <option value=""></option>
                    <option v-for="city in cities" :value="city.id" :key="city.id">{{ city.name }}</option>
                  </select>
                </div>
                <div class="reg_input">
                  <span>Название организации</span>
                  <input type="text" required v-model="orgName" placeholder="Введите название">
                </div>
                <div class="reg_input">
                  <span>Платежные реквизиты</span>
                  <input type="text" required v-model="accountDetail" placeholder="Укажите реквизиты">
                </div>
                <div class="reg_input">
                  <span>Контактные данные</span>
                  <input type="text" required v-model="contacts" placeholder="Укажите данные">
                </div>
                <div class="reg_input">
                  <span>E-mail</span>
                  <input type="text" required v-model="managerEmail" placeholder="Укажите e-mail ">
                </div>

              </div>

            </div>


          </div>
          <div class="btn-blue-shadow" style="    margin: 25px 0; width: 400px;" v-bind="{ style: !(orgName && orgType && managerEmail && contacts && accountDetail && orgLink && address && legalData) ? 'cursor: not-allowed;' : '' }" @click="orgName && orgType && managerEmail && contacts && accountDetail && orgLink && address && legalData ? submitApplication() : null">Отправить</div>
        </div>
        <div v-else class="container_req">
          <h1>Спасибо за заявку, Ваша заявка была отправлена на обработку. В случае успеха Ваши данные для входа будут высланы Вам на почту.</h1>
        </div>
      </section>
    </main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from 'axios';

export default {
  name: "ApplicationForm",
  data() {
    return {
      selectedCountry: 0,
      selectedCity: 0,
      orgType: 0,
      orgName: '',
      managerEmail: '',
      contacts: '',
      accountDetail: '',
      orgLink: '',
      address: '',
      legalData: '',
      dataSent: false
    }
  },
  computed: {
    ...mapGetters({
      countries: 'countries',
      cities: 'cities',
      organisationTypes: 'organisationTypes',
    })
  },
  async mounted() {
    this.$root.setTitle('Вернуться на главную', '/');
    document.title = `Questbook | Подача заявки`;
    await this.$root.loading(async () => {
      await this.getCountries();
      await this.getCities(this.countries[0].id);
    })
  },
  methods: {
    ...mapActions({
      getCountries: 'getCountries',
      getCities: 'getCities'
    }),
    async updateCities() {
      if (this.selectedCountry) {
        await this.$root.loading(async () => {
          await this.getCities(this.selectedCountry);
        });
      }
    },
    async submitApplication() {
      let resp = await axios.post('/organisation/apply', {
        city_id: this.selectedCity ? this.selectedCity : null,
        organisation_type: this.orgType,
        organisation_name: this.orgName,
        legal_data: this.legalData,
        account_detail: this.accountDetail,
        manager_email: this.managerEmail,
        contacts: this.contacts,
        address: this.address,
        link: this.orgLink
      })
      if (resp.data.success) this.dataSent = true;
    }
  }
}
</script>
<style scoped>
.reg_input {
      display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.reg_input input {
    width: 300px;
    height: 40px;
    padding: 5px;
    border: 1px solid rgba(0, 108, 144, 0.41);
    border-radius: 10px;
}

</style>
