import axios from 'axios';

const state = {
    userData: null,
    questsHistory: [],
    questsPages: 1
}

const getters = {
    isAuthenticated: state => !!state.userData,
    activeUser: state => state.userData,
    questsHistory: state => state.questsHistory,
    questsPages: state => state.questsPages
}

const actions = {
    async login({ commit }, data) {
        let resp = await axios.post('/user/login', data);
        if (!resp || resp.status === 404) commit('setUser', null);
        else if (resp.data.success) commit('setUser', resp.data.data);
    },
    async logout({ commit }) {
        await axios.post('/user/logout');
        commit('setUser', null);
    },
    async refreshProfile({ commit }) {
        let resp = await axios.get('/user/profile');
        commit('setUser', resp.data);
    },
    async editProfile({ commit }, newData) {
        let formData = new FormData();
        if (newData.phone !== undefined) formData.append("phone", newData.phone);
        if (newData.avatar) formData.append("avatar", newData.avatar);
        if (newData.name !== undefined) formData.append("full_name", newData.name);
        if (newData.email !== undefined) formData.append("email", newData.email);
        let response = await axios.post('/user/edit', formData);
        commit('setUser', response.data);
    },
    async register({ commit }, data) {
        let response = await axios.post('/user/register', data);
        commit('setUser', response.data.data);
    },
    async getQuests({ commit }, data) {
        let response = await axios.get('/user/quests', data);
        commit('setQuestsData', response.data);
    },
    async getBalanceHistory({ commit }, data) {
        let response = await axios.get('/user/balance-history', data);
        commit('setQuestsData', response.data);
    }
}


const mutations = {
    setUser(state, data) {
        state.userData = data;
    },
    setQuestsData(state, data) {
        state.questsHistory = data.data;
        state.questsPages = data.max_page;
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}