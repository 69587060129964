import axios from 'axios';

const state = {
    videos: []
}

const getters = {
    videos: state => state.videos
}

const actions = {
    async fetchVideos({ commit }) {
        let resp = await axios.get('/videos/list');
        commit('setVideos', resp.data);
    }
}


const mutations = {
    setVideos(state, videos) {
        state.videos = videos;
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}