import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/components/Main.vue';
import LandingView from '@/components/LandingPage.vue';
import LoginView from '@/components/Login.vue';
import RegisterView from '@/components/Register.vue';
import ProfileView from '@/components/Profile.vue';
import { store } from '@/store';
import ApplicationForm from "@/components/ApplicationForm";
import Contacts from "@/components/Contacts";
import AboutUs from "@/components/AboutUs";
import Support from "@/components/Support";
import ResetPassword from "@/components/ResetPassword";


const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/map',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {requiresAuth: true}
  },
  {
    path: '/apply',
    name: 'application',
    component: ApplicationForm
  },
  {
    path: '/about',
    name: 'aboutUs',
    component: AboutUs
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  },
  {
    path: '/contact',
    name: 'support',
    component: Support
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if ( (to.path === '/login' || to.path === '/register' ) && store.getters.isAuthenticated) next('/profile');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router
