import { createApp } from 'vue'
import { store } from '@/store';
import axios from 'axios';
import router from './router'

import App from './App.vue'


axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch('logOut');
      return router.push('/login')
    }
  }
});
axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/api';  // the FastAPI backend

createApp(App).use(store).use(router).mount('#app')
