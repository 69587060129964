<template>
    <main>
      <section class="about-us">
        <div class="container">
          <div class="about__box">
            <div class="about__left">
              <div class="about__form-inputs">
                <p class="about_items">Способ связи</p>
                <input type="text" v-model="contactData">
                <p class="about_items">Описание проблемы</p>
                <textarea id="" cols="30" rows="10" v-model="errorData"></textarea>
                <a class="btn-blue-shadow" @click="onSubmit()">Отправить</a>
              </div>
            </div>
            <div class="about__right">
              <img src="/static/img/support_img.png" alt="">

            </div>
          </div>

        </div>

      </section>
    </main>
</template>
<script>
import axios from 'axios';

export default {
  name: "LandingPage",
  data() {
    return {
      errorData: "",
      contactData: ""
    }
  },
  async mounted() {
    this.$root.setTitle('Поддержка');
  },
  methods: {
    async onSubmit() {
      let resp = await axios.post('/landing/report', {contact_method: this.contactData, report_text: this.errorData});
      if (resp.data.success) {
        this.errorData = '';
        alert('Спасибо за Ваше сообщение! Мы ответим вам в ближайшее время.');
      }
    }
  }
}

</script>