<template>
  <main>
    <section class="hero-index">
      <div class="container_req">
        <div class="quest__form-reginputs">
          <h1 style="text-align: center;">Введите логин для восстановления пароля</h1>
          <div class="reg_input-code" style="margin-top: 15px;">
            <span>На вашу почту будет отправлен новый пароль</span>
            <input type="text" @focusin="codeSent = false" v-model="email" placeholder="Почта">
          </div>
          <p v-if="codeSent">На вашу почту был отправлен новый пароль, используйте его для входа.</p>
          <div class="btn-blue-shadow_reg" @click="submitForm()" style="width: 90%; cursor: pointer">Отправить</div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from 'axios';


export default {
  name: "ResetPassword",
  data() {
    return {
      email: '',
      codeSent: false
    }
  },
  methods: {
    async submitForm() {
      let response = await axios.post(`/user/ask-reset?email=${this.email}`);
      if (response.data.status) this.codeSent = true;
    }
  }
}
</script>

<style scoped>

</style>