<template>
  <div>
    <main>
      <section class="hero-index">
        <div class="container_req">

          <div class="quest__form-reginputs">
              <h1>Авторизация</h1>
              <div class="reg_input">
                  <span>Введите адрес электроной почты</span>
                  <input type="email" v-model="email" @focusin="badData = false;"  @change="emailValid = $root.validateEmail(email)" placeholder="Адрес электронной почты">
                  <p v-if="!emailValid" class="reg-input__invalid">Неправильный адрес почты</p>
              </div>
              <div class="reg_input">
                  <span>Введите пароль</span>
                  <input type="password" v-model="password" @focusin="badData = false;" @input="pwdValid = $root.validatePassword(password)" placeholder="Пароль">
                  <p v-if="!pwdValid" class="reg-input__invalid">Введите пароль</p>
              </div>

              <p v-if="badData" class="reg-input__invalid">Неправильные данные входа</p>
              <div class="btn-blue-shadow_reg" v-bind="{ style: !(pwdValid && emailValid) ? 'cursor: not-allowed;' : '' }" @click="pwdValid && emailValid ? login() : null">Войти</div>
              <router-link to="/register" class="sign_in-button" style="margin-bottom: 0 !important;">Зарегистрироваться</router-link>
              <router-link to="/reset-password" class="sign_in-button">Забыли пароль?</router-link>

          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      email: null,
      password: null,
      emailValid: false,
      badData: false,
      pwdValid: false
    }
  },
  methods: {
    ...mapActions({ _login: 'login' }),

    async login() {
      await this._login({ email: this.email, password: this.password });
      if (!this.isAuthenticated) this.badData = true;
      await this.$router.push('/profile');
    }
  },
  mounted() {
    this.$root.setTitle('Вход');
  }
}
</script>

<style>
.reg-input__invalid {
  color: #8f1717;
}
.unactive{
    color:silver !important;
    border-color:silver !important;
    cursor:not-allowed !important;
}
.unactive:hover{
    color:silver !important;
    box-shadow: 0px 0px 0px 0px silver !important;
    border-color:silver !important;
    cursor:not-allowed !important;
}
</style>
