<template>
      <main>
      <section class="about-us">
        <div class="container">
          <div class="about__box">
            <div class="about__left" v-html="aboutUs">

            </div>
            <div class="about__right">
              <img src="/static/img/about_img.png" alt="">
            </div>
          </div>

        </div>

      </section>
    </main>
</template>
<script>
import axios from 'axios';

export default {
  name: "LandingPage",
  data() {
    return {
      aboutUs: ""
    }
  },
  async mounted() {
    this.$root.setTitle('О Нас');
    await this.$root.loading(async () => {
          let resp = await axios.get('/landing/settings?page=about');
          this.aboutUs = resp.data.about_us;
    });

  }
}

</script>