<template>
    <main v-if="!codeStep">
      <section class="hero-index">
        <div class="container_req">

          <div class="quest__form-reginputs">
              <h1>Регистрация</h1>
              <div class="reg_input">
                  <span>Введите ваше имя</span>
                  <input type="text" v-model="fullName" placeholder="Имя">
              </div>
              <div class="reg_input">
                  <span>Введите адрес электроной почты</span>
                  <input type="email" v-model="email" @change="checkValidity()" placeholder="Адрес электронной почты">
                  <p v-if="!$root.validateEmail(email)" class="reg-input__invalid">Неправильный адрес почты</p>
              </div>
              <div class="reg_input">
                  <span>Введите ваш телефон</span>
                  <input type="text" v-model="phoneNumber" @change="checkValidity()" placeholder="Телефон">
                  <p v-if="!$root.validatePhone(phoneNumber)" class="reg-input__invalid">Неправильный номер телефона</p>
              </div>
              <div class="reg_input">
                  <span>Введите пароль</span>
                  <input type="password" v-model="password" @change="checkValidity()" placeholder="Пароль">
                  <p v-if="!$root.validatePassword(password)" class="reg-input__invalid">Минимальная длина пароля - 8</p>
              </div>
              <div class="reg_input">
                  <span>Повторите пароль</span>
                  <input type="password" v-model="passwordRepeat" @input="checkValidity()" placeholder="Повторите пароль">
                  <p v-if="passwordRepeat !== password" class="reg-input__invalid">Пароли не совпадают</p>
              </div>

              <div :class="`btn-blue-shadow_reg ${!formValid ? 'unactive' : ''}`" v-bind="{ style: !formValid ? 'cursor: not-allowed;' : '' }"  @click="registerStep">Зарегистрироваться</div>
              <router-link to="/login" class="sign_in-button">Уже зарегистрированы? Войти</router-link>

          </div>
        </div>
      </section>
    </main>
    <main v-else>
          <section class="hero-index">
            <div class="container_req">

              <div class="quest__form-reginputs">
                  <h1>Подтвердите адрес электронной почты</h1>
                  <div class="reg_input-code">
                      <span>На вашу почту отправлен 6-значный код, введите его</span>
                      <input type="text" v-model="emailCode" placeholder="Код">
                  </div>
                  <div class="btn-blue-shadow_reg" @click="register()">Зарегестрироваться</div>
              </div>
            </div>
          </section>
    </main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RegisterView",
  data() {
    return {
      email: null,
      password: null,
      fullName: null,
      phoneNumber: null,
      passwordRepeat: null,
      emailCode: null,
      codeStep: false,
      formValid: false
    }
  },
  computed: {
    ...mapGetters({ isAuthenticated: 'isAuthenticated' })
  },
  methods: {
    ...mapActions({ _register: 'register' }),

    async register() {
      await this._register({
        email: this.email,
        password: this.password,
        full_name: this.fullName,
        phone_number: this.phoneNumber,
        code: this.emailCode
      })
      await this.$router.push('/profile');
    },
    async registerStep() {
      if (this.formValid) {
        this._register({
          email: this.email,
          password: this.password,
          full_name: this.fullName,
          phone_number: this.phoneNumber,
        })
        this.codeStep = true;
      }
    },
    checkValidity() {
      this.formValid = this.password === this.passwordRepeat && this.$root.validatePassword(this.password) && this.$root.validatePhone(this.phoneNumber) && this.$root.validateEmail(this.email);
    }
  },
    mounted() {
          this.$root.setTitle('Регистрация');
    }
}
</script>

<style scoped>

</style>
