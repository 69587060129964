<template>
  <header>
    <div class="container">
      <div class="navbar" v-if="$route.path !== '/'">
        <div>
          <div class="logo"><a :href="$route.path === '/profile' ? '/map' : '/'"><img src="/static/img/logo.png"
                                                                                      alt=""/></a></div>
          <ul>
            <li>
              <router-link v-if="titleHref" :to="titleHref">{{ title }}</router-link>
              <a v-else>{{ title }}</a>
            </li>
          </ul>
        </div>
        <div>
          <router-link v-if="isAuthenticated" class="header-icon" to="/profile"><img src="/static/img/user-btn.svg"
                                                                                     alt=""/></router-link>
          <a style="cursor: pointer" v-if="isAuthenticated" class="header-icon" @click="logOut();"><img
              src="/static/img/sign-btn.svg" alt=""/></a>
          <router-link to="/login" v-else class="header-icon"><img src="/static/img/sign-btn.svg" alt=""/></router-link>
        </div>
      </div>
      <div v-else class="navbar">
        <div class="hamburger-menu">
          <input id="menu__toggle" type="checkbox"/>
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>

          <ul class="menu__box">
            <li>
              <router-link class="menu__item" to="/map">Карта</router-link>
            </li>
            <li><a class="menu__item" @click="goTo('howItWorks')">Как это работает</a></li>
            <li><a class="menu__item" href="/about">О нас</a></li>
            <li><a class="menu__item" @click="goTo('whyUs')">Почему мы</a></li>
            <li><a class="menu__item" href="/contact">Обратная связь</a></li>
          </ul>

        </div>
        <div class="desktop-menu">
          <ul class="desktop-menu__box">
            <li><a class="desktop-menu__item" href="/map">Карта</a></li>
            <li><a class="desktop-menu__item" @click="goTo('howItWorks')">Как это работает</a></li>
            <li><a class="desktop-menu__item" href="/about">О нас</a></li>
            <li><a class="desktop-menu__item" @click="goTo('whyUs')">Почему мы</a></li>
            <li><a class="desktop-menu__item" href="/contact">Обратная связь</a></li>
          </ul>

        </div>
        <div class="authico__box" v-if="isAuthenticated">
          <a href="/profile"><img src="/static/img/authico.svg" alt=""></a>
        </div>

      </div>
      <div v-if="$route.path === '/' && !isLoading" class="head__box">
        <div class="slogan_box">
          <span class="slogan__top" v-html="landingSettings.slogan_up"></span>
          <span class="slogan__bottom" v-html="landingSettings.slogan_low"></span>
        </div>
        <div class="buttons__box">
          <div class="logo"><img src="/static/img/logowhite.png" alt=""></div>
          <a href="/register" class="button">Зарегистрироваться</a>
          <a href="/login">Уже зарегистрированы? Войти</a>
        </div>
      </div>
      <div class="box__request" v-if="$route.path === '/' && !isLoading">
        <span v-html="landingSettings.application_label_slogan"></span>
        <a href="/apply">{{ landingSettings.button_label }}</a>
      </div>
    </div>
    <video v-if="$route.path === '/' && !isLoading" class="layer" preload="auto" ref="video"
           loop="" muted="" autoplay="" playsinline="">
      <source ref="videoSource" :src="landingSettings.main_page_video" type="video/mp4">
    </video>
  </header>

  <router-view v-bind="{ style: isLoading ? 'display: none;' : '' }" v-if="!reload"/>
  <div class="_loader" v-if="isLoading">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="modal-item" data-modal-item="how-use-balls">
    <div class="HUB-modal">
      <div class="h4">Как пользоваться баллами?</div>
      <p>Баллы вы можете списать как скидку на тур, в котором вы их заработали</p>
      <div class="btn-blue-shadow modal-btn-close">Закрыть</div>
    </div>
  </div>
  <footer>
    <div class="container">
      <div class="logo-footer"><img src="/static/img/logo.png" alt=""/></div>
      <div class="footer-lists">
        <ul>
          <li>Информация</li>
          <li><a href="/about">О нас</a></li>
        </ul>
        <ul>
          <li>Юридическая информация</li>
          <li><a href="javascript:void(0)">Условия использования сайта</a></li>
          <li><a href="javascript:void(0)">Пользовательское соглашение</a></li>
          <li><a href="javascript:void(0)">Политика конфиденциальности</a></li>
        </ul>
        <ul>
          <li>Помощь</li>
          <li><a href="/contact">Поддержка</a></li>
          <li><a href="/contacts">Контакты</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import axios from "axios";

export default {
  name: 'App',
  data() {
    return {
      isLoading: false,
      reload: false,
      title: 'Questbook',
      titleHref: null,
      landingSettings: {}
    }
  },
  methods: {
    ...mapActions({logout: 'logout'}),

    async loading(asyncCallable) {
      this.isLoading = true;
      await asyncCallable();
      this.isLoading = false;
    },
    async reloadMain() {
      this.reload = true;

      await new Promise((resolve) => {
        setTimeout(resolve, 1)
      });
      this.reload = false;
    },
    setTitle(title, href = null) {
      document.title = `Questbook | ${title}`;
      this.title = title;
      if (href !== null) this.titleHref = href;
    },
    async logOut() {
      await this.logout();
      await this.$router.push('/login');
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    goTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      });
    },
    validatePassword(pwd) {
      return !!pwd && String(pwd).length >= 8;
    },
    validatePhone(phoneNum) {
      return String(phoneNum).match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
    }
  },
  updated() {
    if (this.$route.path === '/') {
      document.getElementById('mainStyle').disabled = true;
      document.getElementById('landingStyle').disabled = false;
    } else {
      document.getElementById('mainStyle').disabled = false;
      document.getElementById('landingStyle').disabled = true;
    }
  },
  async mounted() {
    if (this.$route.path === '/') {
      await this.$root.loading(async () => {
        let resp = await axios.get('/landing/settings?page=main');
        this.landingSettings = resp.data;
      });
      this.$nextTick(() => {
        this.$refs.video.load();
        this.$refs.video.play();
      });
    }
  },
  computed: {
    ...mapGetters({isAuthenticated: 'isAuthenticated'})
  }
}
</script>
<style scoped>
._loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000000;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
